import router from './router/index.js'
import { createApp } from 'vue'
import {store } from './state/store.js'
import App from './app.vue'
import 'moment/dist/locale/nl';

const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app');
app.mixin({
    mounted() {
        this.$el.__vue__ = this;
    }
});
app.directive('click-outside', {
    beforeMount(el, binding) {
        el.clickOutsideEvent = event => {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event);
            }
        };
        document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.removeEventListener('click', el.clickOutsideEvent);
    },
});
export default [
	{
		path: '/', component: () => import('../views/home.vue')
	},
	{
		path: '/verversen', component: () => import('../views/account-refresh.vue')
	},
	{
		path: '/instellingen', component: () => import('../views/account-settings.vue')
	},
	{
		path: '/inloggen', component: () => import('../views/account-login.vue')
	},
	{
		path: '/uitloggen', component: () => import('../views/account-logout.vue')
	},
	{
		path: '/wachtwoordvergeten', component: () => import('../views/account-forgot-password.vue')
	},
	{
		path: '/wachtwoordbevestigen', component: () => import('../views/account-forgot-password-confirmation.vue')
	},
	{
		path: '/wachtwoordinstellen', component: () => import('../views/account-set-password.vue')
	},
	{
		path: '/wachtwoordingesteld', component: () => import('../views/account-set-password-confirmation.vue')
	},
	{
		path: '/importeren', component: () => import('../views/bank-import.vue')
	},
	{
		path: '/banktransacties', component: () => import('../views/bank-transaction.vue')
	},
	{
		path: '/offertes', component: () => import('../views/quotations.vue')
	},
	{
		path: '/kasboek', component: () => import('../views/transactions.vue')
	},
	{
		path: '/todos', component: () => import('../views/todos.vue')
	},
	{
		path: '/bedrijfsinfo', component: () => import('../views/company-info.vue')
	},
	{
		path: '/betaling', component: () => import('../views/payment-start.vue')
	},
	{
		path: '/betalingstatus', component: () => import('../views/payment-status.vue')
	},
	{
		path: '/betaling-belasting-verwerken', component: () => import('../views/payment-tax-status.vue')
	},
	{
		path: '/cijferbaas', component: () => import('../views/company-info-accountant.vue')
	},
	{
		path: '/bedrijf', component: () => import('../views/company-info-data.vue')
	},
	{
		path: '/klanten', component: () => import('../views/company-info-customers.vue')
	},
	{
		path: '/facturatie', component: () => import('../views/company-info-invoice-info.vue')
	},
	{
		path: '/resultaten', component: () => import('../views/dashboard.vue')
	},
	{
		path: '/aangiftes', component: () => import('../views/tax-returns.vue')
	},
	{
		path: '/assets', component: () => import('../views/assets.vue')
	},
	{
		path: '/error-bank', component: () => import('../views/error-banking.vue')
	},
	{
		path: '/404', component: () => import('../views/error-404.vue')
	},
	{
		path: '/geen-toegang', component: () => import('../views/error-access-denied.vue')
	},
	{
		path: '/offerte-accepteren', component: () => import('../views/quote-signing.vue')
	},
	{
		path: '/machtiging', component: () => import('../views/mandate.vue')
	},
	{
		path: '/monitor', component: () => import('../views/admin-monitoring.vue')
	},
	{
		path: '/klantenbeheer', component: () => import('../views/admin-customers.vue')
	},
	{
		path: '/klant', component: () => import('../views/admin-customer.vue')
	},	
	{
		path: '/vorige-aangiftes', component: () => import('../views/admin-previous-tax-returns.vue')
	},
	{
		path: '/cijferbaasrechten', component: () => import('../views/admin-accountant-users.vue')
	},
	{
		path: '/datasets', component: () => import('../views/admin-datasets.vue')
	},
	{
		path: '/chatgpt', component: () => import('../views/admin-chatgpt.vue')
	},
	{
		path: '/jobs', component: () => import('../views/admin-jobs.vue')
	},
	{
		path: '/personen', component: () => import('../views/admin-persons.vue')
	},
	{
		path: '/systeemberichten', component: () => import('../views/admin-system-messages.vue')
	},
	{
		path: '/blokkades', component: () => import('../views/admin-frozen-periods.vue')
	},
	{
		path: '/machtigingen', component: () => import('../views/admin-tax-mandates.vue')
	},
	{
		path: "/oauthapplications", component: () => import('../views/admin-oauth-applications.vue')
	},
	{
		path: "/identiteitscontrole", component: () => import('../views/admin-identification-check.vue')
	},	
	{
		path: "/kleinschaligheidsinvesteringsaftrek", component: () => import('../views/admin-investment-categories.vue')
	},
	{
		path: "/import-account", component: () => import('../views/admin-import.vue')
	},
	{
		path: "/icp-aangiften", component: () => import('../views/admin-tax-icp-returns.vue')
	},
	{
		path: "/ib-facturen", component: () => import('../views/admin-tax-incometax-invoices.vue')
	},
	{
		path: "/ib-facturen", component: () => import('../views/admin-tax-incometax-invoices.vue')
	},
	{
		path: "/ib-planning", component: () => import('../views/admin-tax-incometax-planning.vue')
	},
	{
		path: "/omzetbelasting", component: () => import('../views/admin-tax-vat-returns.vue')
	},
	{
		path: "/inkomstenbelasting", component: () => import('../views/admin-tax-incometaxes.vue')
	},
	{
		path: "/voorlopigeaanslag", component: () => import('../views/admin-tax-preliminary-incometaxes.vue')
	},
	{
		path: "/quickaudit", component: () => import('../views/admin-audit-quick.vue')
	},
	{
		path: "/export", component: () => import('../views/admin-export.vue')
	},
	{
		path: "/audit", component: () => import('../views/admin-audit.vue')
	},
	{
		path: "/ib-capaciteit", component: () => import('../views/admin-accountant-capacity-income-tax.vue')
	},
	{
		path: "/audit-details", component: () => import('../views/admin-audit-details.vue')
	},
	{
		path: "/balansboekingen", component: () => import('../views/admin-balance-items.vue')
	},
	{
		path: "/balansboekingen/:companyId",
		component: () => import('../views/admin-balance-items.vue'),
		props: true
	},
	{
		path: "/investeringen", component: () => import('../views/investments.vue')
	},
	{
		path: "/dashboard", component: () => import('../views/admin-dashboard.vue')
	},
	{
		path: "/dorkmail", component: () => import('../views/booking-proposition-overview.vue')
	},
	{
		path: "/uren", component: () => import('../views/hour-registration-overview.vue')
	},
	{
		path: "/abonnementen", component: () => import('../views/subscriptions.vue')
	},
	{
		path: "/abonnementen-goedkeuren", component: () => import('../views/subscription-review.vue')
	},	
	{
		path: "/selecteer-bedrijf", component: () => import('../views/company-select.vue')
	},
	{
		path: "/suppression", component: () => import('../views/remove-suppression.vue')
	},
	{
		path: "/scan-en-herken", component: () => import('../views/admin-scan-categories.vue')
	},
	{
      path: "/:catchAll(.*)", redirect: '404'
	},
];

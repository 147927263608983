"use strict";

export default function dateTime(...args) {
	return new DateTime(...args);
}

export class DateTime extends Date {
	constructor(...args) {
		if (args.length === 1 && args[0] instanceof Array) {
			args = Object.assign([1, 0, 1, 0, 0, 0], args[0]);
		}

		super(...args);
	}

	/**
	 * @param {number} increment
	 * @param {string} interval
	 * 	"year" | "years" | "y" |
	 * 	"quarter" | "quarters" | "Q" |
	 * 	"month" | "months" | "M" |
	 * 	"week" | "weeks" | "W" |
	 * 	"day" | "days" | "d" |
	 * 	"hour" | "hours" | "h" |
	 * 	"minute" | "minutes" | "m" |
	 * 	"second" | "seconds" | "s" |
	 * 	"millisecond" | "milliseconds" | "ms"
	 */
	add(increment, interval) {
		interval = this.#normalizeInterval(interval);

		const newDate = new DateTime(this);

		// noinspection FallThroughInSwitchStatementJS
		switch (interval) {
			case "year":
				increment *= 4;
			case "quarter":
				increment *= 3;
			case "month":
				const targetMonth = this.getMonth() + increment;
				const expectedResultMonth = (targetMonth % 12 + 12) % 12;
				newDate.setMonth(targetMonth);

				if (newDate.getMonth() !== expectedResultMonth) {
					newDate.setDate(0);
				}

				break;

			case "week":
				increment *= 7;
			case "day":
				increment *= 24;
			case "hour":
				increment *= 60;
			case "minute":
				increment *= 60;
			case "second":
				increment *= 1000;
			case "millisecond":
				newDate.setMilliseconds(this.getMilliseconds() + increment);
				break;
		}

		return newDate;
	}

	/**
	 * @param {number} decrement
	 * @param {string} interval
	 * 	"year" | "years" | "y" |
	 * 	"quarter" | "quarters" | "Q" |
	 * 	"month" | "months" | "M" |
	 * 	"week" | "weeks" | "W" |
	 * 	"day" | "days" | "d" |
	 * 	"hour" | "hours" | "h" |
	 * 	"minute" | "minutes" | "m" |
	 * 	"second" | "seconds" | "s" |
	 * 	"millisecond" | "milliseconds" | "ms"
	 */
	subtract(decrement, interval) {
		let oppositeValue = decrement * -1;
		return this.add(oppositeValue, interval);
	}

	/**
	 * @param {string} pattern
	 * @returns {string}
	 */
	format(pattern) {
		if (!pattern) {
			return "";
		}

		const tags = /(YYYY|yyyy|YY|yy|Q|MMMM|MMM|MM|M|WW|W|dddd|ddd|DD|dd|d|HH|H|hh|h|mm|m|ss|s|fff)/g;
		const parts = pattern.split(/\[/g);

		let result = "";

		for (let i = 0; i < parts.length; i++) {
			let part = parts[i];

			if (i > 0) {
				let escapeIndex = part.indexOf("]");

				if (escapeIndex !== -1) {
					result += part.substring(0, escapeIndex);
					part = part.substring(escapeIndex + 1);
				} else {
					result += "[";
				}
			}

			result += part.replace(tags, tag => this.#format(tag));
		}

		return result;
	}

	/**
	 * @param {string} interval
	 * 	"year" | "years" | "y" |
	 * 	"quarter" | "quarters" | "Q" |
	 * 	"month" | "months" | "M" |
	 * 	"week" | "weeks" | "W" |
	 * 	"day" | "days" | "d" |
	 * 	"hour" | "hours" | "h" |
	 * 	"minute" | "minutes" | "m" |
	 * 	"second" | "seconds" | "s"
	 */
	startOf(interval) {
		interval = this.#normalizeInterval(interval);

		const newDate = new DateTime(this);
		let targetMonth = this.getMonth();

		// noinspection FallThroughInSwitchStatementJS
		switch (interval) {
			case "year":
				targetMonth = 0;
			case "quarter":
				targetMonth = Math.floor(targetMonth / 3) * 3;
			case "month":
				newDate.setMonth(targetMonth, 1);
				break;

			case "week":
				const day = newDate.getDay();
				const offset = day === 0 ? 6 : day - 1;
				newDate.setDate(this.getDate() - offset);
				break;
		}

		// noinspection FallThroughInSwitchStatementJS
		switch (interval) {
			case "year":
			case "quarter":
			case "month":
			case "week":
			case "day":
				newDate.setHours(0);
			case "hour":
				newDate.setMinutes(0);
			case "minute":
				newDate.setSeconds(0);
			case "second":
				newDate.setMilliseconds(0);
		}

		return newDate;
	}

	/**
	 * @param {string} interval
	 * 	"year" | "years" | "y" |
	 * 	"quarter" | "quarters" | "Q" |
	 * 	"month" | "months" | "M" |
	 * 	"week" | "weeks" | "W" |
	 * 	"day" | "days" | "d" |
	 * 	"hour" | "hours" | "h" |
	 * 	"minute" | "minutes" | "m" |
	 * 	"second" | "seconds" | "s"
	 */
	endOf(interval) {
		interval = this.#normalizeInterval(interval);

		const newDate = new DateTime(this);
		let targetMonth = this.getMonth();

		// noinspection FallThroughInSwitchStatementJS
		switch (interval) {
			case "year":
				targetMonth = 11;
			case "quarter":
				targetMonth += 2 - targetMonth % 3;
			case "month":
				newDate.setMonth(targetMonth + 1, 0);
				break;

			case "week":
				const day = newDate.getDay();
				const offset = day === 0 ? 6 : day - 1;
				newDate.setDate(this.getDate() - offset);
				break;
		}

		// noinspection FallThroughInSwitchStatementJS
		switch (interval) {
			case "year":
			case "quarter":
			case "month":
			case "week":
			case "day":
				newDate.setHours(23);
			case "hour":
				newDate.setMinutes(59);
			case "minute":
				newDate.setSeconds(59);
			case "second":
				newDate.setMilliseconds(999);
		}

		return newDate;
	}

	/**
	 * @param {string} interval
	 * 	"year" | "years" | "y" |
	 * 	"quarter" | "quarters" | "Q" |
	 * 	"month" | "months" | "M" |
	 * 	"week" | "weeks" | "W" |
	 * 	"weekYear" | "weekYears" | "GG" |
	 * 	"day" | "days" | "d" |
	 * 	"hour" | "hours" | "h" |
	 * 	"minute" | "minutes" | "m" |
	 * 	"second" | "seconds" | "s"
	 * 	"millisecond" | "milliseconds" | "ms"
	 * @return {number}
	 */
	get(interval) {
		interval = this.#normalizeInterval(interval);

		switch (interval) {
			case "year":
				return this.getFullYear();
			case "quarter":
				return Math.floor(this.getMonth() / 3) + 1;
			case "month":
				return this.getMonth();
			case "week":
				return this.#getWeekAndYear().week;
			case "weekYear":
				return this.#getWeekAndYear().year;
			case "date":
				return this.getDate();
			case "hour":
				return this.getHours();
			case "minute":
				return this.getMinutes();
			case "second":
				return this.getSeconds();
			case "millisecond":
				return this.getMilliseconds();
		}

		return undefined;
	}

	/**
	 * @param {string} interval
	 * 	"year" | "years" | "y" |
	 * 	"quarter" | "quarters" | "Q" |
	 * 	"month" | "months" | "M" |
	 * 	"day" | "days" | "d" |
	 * 	"hour" | "hours" | "h" |
	 * 	"minute" | "minutes" | "m" |
	 * 	"second" | "seconds" | "s"
	 * 	"millisecond" | "milliseconds" | "ms"
	 * @param {number} value
	 * @return {DateTime}
	 */
	set(interval, value) {
		interval = this.#normalizeInterval(interval);

		const newDate = new DateTime(this);

		// noinspection FallThroughInSwitchStatementJS
		switch (interval) {
			case "year":
				newDate.setFullYear(value);
				if (newDate.getMonth() !== this.getMonth()) {
					newDate.setDate(0);
				}
				break;
			case "quarter":
				value = 3 * value - 3;
			case "month":
				const expectedResultMonth = (value % 12 + 12) % 12;
				newDate.setMonth(value);

				if (newDate.getMonth() !== expectedResultMonth) {
					newDate.setDate(0);
				}
				break;
			case "date":
				newDate.setDate(value);
				break;
			case "hour":
				newDate.setHours(value);
				break;
			case "minute":
				newDate.setMinutes(value);
				break;
			case "second":
				newDate.setSeconds(value);
				break;
			case "millisecond":
				newDate.setMilliseconds(value);
				break;
		}

		return newDate;
	}

	/**
	 * @param {string} tag
	 * 	"yyyy" | "yy" |
	 * 	"Q" |
	 * 	"MMMM" | "MMM" | "MM" | "M" |
	 * 	"dddd" | "ddd" | "dd" | "d" |
	 * 	"HH" | "H" | "hh" | "h" |
	 * 	"mm" | "m" |
	 * 	"ss" | "s" |
	 * 	"fff"
	 * @returns {string}
	 */
	#format(tag) {
		const locale = "nl-NL";

		switch (tag) {
			case "YYYY":
			case "YY":
			case "yyyy":
			case "yy":
				const year = tag.length === 2 ? "2-digit" : "numeric";
				return this.toLocaleString(locale, {year});

			case "Q":
				return `${Math.floor(this.getMonth() / 3) + 1}`;

			case "MMMM":
			case "MMM":
			case "MM":
			case "M":
				const monthValues = ["numeric", "2-digit", "short", "long"];
				const month = monthValues[tag.length - 1];
				return this.toLocaleString(locale, {month});

			case "WW":
			case "W":
				const week = this.#getWeekAndYear().week;
				return week.toString().padStart(tag.length, "0");

			case "DDDD":
			case "DDD":
			case "dddd":
			case "ddd":
				const weekday = tag.length === 4 ? "long" : "short";
				return this.toLocaleString(locale, {weekday});

			case "DD":
			case "D":
			case "dd":
			case "d":
				const day = tag.length === 2 ? "2-digit" : "numeric";
				return this.toLocaleString(locale, {day});

			case "HH":
			case "H":
			case "hh":
			case "h":
				return this.getHours().toString().padStart(tag.length, "0");

			case "mm":
			case "m":
				return this.getMinutes().toString().padStart(tag.length, "0");

			case "ss":
			case "s":
				return this.getSeconds().toString().padStart(tag.length, "0");

			case "fff":
				return this.getMilliseconds().toString().padStart(3, "0");
		}

		return "";
	}

	/**
	 * @return {{week: number, year: number}}
	 */
	#getWeekAndYear() {
		const date = new DateTime(this).set("date", this.getDate() + 4 - (this.getDay() || 7));
		const yearStart = new Date(date.getFullYear(), 0, 1);

		const week = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
		const year = date.getFullYear();

		return {week, year};
	}

	/**
	 * @param {string} interval
	 * @return {string}
	 */
	#normalizeInterval(interval) {
		// noinspection SpellCheckingInspection
		const aliases = {
			D: "date",
			dates: "date",
			date: "date",
			d: "day",
			days: "day",
			day: "day",
			E: "weekday",
			isoweekdays: "weekday",
			isoweekday: "weekday",
			DDD: "dayOfYear",
			dayofyears: "dayOfYear",
			dayofyear: "dayOfYear",
			h: "hour",
			hours: "hour",
			hour: "hour",
			ms: "millisecond",
			milliseconds: "millisecond",
			millisecond: "millisecond",
			m: "minute",
			minutes: "minute",
			minute: "minute",
			M: "month",
			months: "month",
			month: "month",
			Q: "quarter",
			quarters: "quarter",
			quarter: "quarter",
			s: "second",
			seconds: "second",
			second: "second",
			GG: "weekYear",
			weekyears: "weekYear",
			weekyear: "weekYear",
			W: "week",
			weeks: "week",
			week: "week",
			y: "year",
			years: "year",
			year: "year",
		};

		return typeof interval === "string"
			? aliases[interval] || aliases[interval.toLowerCase()]
			: undefined;
	}
}